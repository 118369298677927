import { render, staticRenderFns } from "./BiadjosNeedInsurance.vue?vue&type=template&id=3df7741a"
import script from "./BiadjosNeedInsurance.vue?vue&type=script&lang=js"
export * from "./BiadjosNeedInsurance.vue?vue&type=script&lang=js"
import style0 from "./BiadjosNeedInsurance.vue?vue&type=style&index=0&id=3df7741a&prod&lang=scss"
import style1 from "./BiadjosNeedInsurance.vue?vue&type=style&index=1&id=3df7741a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports